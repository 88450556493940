<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :loading="loading"
          :paginator="checkPagination"
          :rows="limit"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="printer_id" header="Printer ID"></Column>
          <Column field="printer_name" header="Printer Name"></Column>
          <Column field="fontsize" header="Font size"></Column>
          <Column field="printer_type" header="Type"></Column>
          <Column field="status" header="Status"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="confirmDelete(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="name">Printer Name:</label>
          <InputText
            id="name"
            v-model.trim="product.name"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !product.name }"
          />
          <small class="p-invalid" v-if="submitted && !product.name"
            >Printer Name is required.</small
          >
        </div>
        <div class="p-field">
          <label for="fontsize">Font Size:</label>
          <InputNumber id="fontsize" v-model="product.fontsize" />
        </div>
        <div class="p-field">
          <label for="type">Type:</label>
          <Dropdown
            v-model="product.type"
            :options="printerType"
            optionLabel="value"
            placeholder="Select type"
          />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product"
            >Are you sure to delete <b>{{ product.receiptHeading }}</b
            >?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="deleted"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PrinterSetup from "../../service/PrinterSetup";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class PrinterSetups extends Vue {
  private imagePath = "";
  private lists = [];
  private printerSetup;
  private keyword = "";
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private loading = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Printer settings" },
  ];
  private toast;

  private selectedPrinterTypeObj = {};
  private printerType = [{ value: "Regular" }, { value: "Dry Cleaning" }];

  private product = {
    id: 0,
    name: "",
    fontsize: 0,
    type: {},
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.printerSetup = new PrinterSetup();
    this.toast = new Toaster();
    this.imagePath = this.printerSetup.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      fontsize: 0,
      type: {},
    };

    this.submitted = false;
    this.dialogTitle = "Add New Service";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.printerSetup.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.printerSetup.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        fontsize: 0,
        type: {},
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Edit Printer";
    this.productDialog = true;

    this.printerSetup.getItem(data).then((res) => {
      if (res) {
        this.printerType.filter((elem) => {
          if (elem.value == res.printer_type) {
            this.selectedPrinterTypeObj = elem;
          }
        });

        this.product = {
          id: res.printer_id,
          name: res.printer_name,
          fontsize: res.fontsize,
          type: this.selectedPrinterTypeObj,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmDelete(data) {
    this.product.id = data.printer_id;

    this.statusDialog = true;
  }

  //DELETE THE iTEM
  deleted() {
    this.statusDialog = false;
    this.printerSetup.deleted(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.printerSetup.getItems(page).then((data) => {
      this.lists = data.print_record;
      this.totalRecords = data.total_printer_count;
      this.limit = data.limit_per_page;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.printerSetup.getSearchedPrinterSetup(this.keyword).then((data) => {
        this.lists = data.print_record;
        this.checkPagination = data.pagination;
      });
    }
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>
