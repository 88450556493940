<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <Button
            icon="pi pi-plus"
            class="p-mr-2 p-button-success"
            @click="openDialog"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column
            field="year"
            header="Year"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="branch_for_id"
            header="Branch"
            headerStyle="width: 150px"
          ></Column>
          <Column
            v-for="n in 53"
            :field="'week' + n"
            :header="'Week ' + n"
            :key="n"
            headerStyle="width: 150px"
          ></Column>
          <Column
            :exportable="false"
            header="Action"
            headerStyle="width: 150px"
          >
            <template #body="slotProps">
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="confirmDelete(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="category">Select Store/Branch:</label>
          <Dropdown
            v-model="projectedSales.branch"
            :options="branchList"
            optionLabel="mall"
            filter="true"
          />
        </div>
        <div class="p-field">
          <label for="category">Year:</label>
          <Dropdown
            v-model="projectedSales.year"
            :options="getTenYears()"
            optionLabel="key"
          />
        </div>
        <div class="p-field" v-for="(n, index) in 53" :key="index">
          <label for="name">Week {{ n }} Sales:</label>
          <InputNumber
            id="weeksales"
            v-model="projectedSales.weeksales[index]"
          />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="projectedSales">Are you sure you want to delete?</span>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="deleted"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ProjectSale from "../../service/ProjectSale";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class ProjectSales extends Vue {
  private lists = [];
  private ProjectSale;
  private branchList;
  private selectedCategory;
  private selectedCategoryObj;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Projected sales" },
  ];
  private toast;

  private projectedSales = {
    id: 0,
    branch: {},
    year: {},
    weeksales: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  getTenYears() {
    const years = [{}];
    const date = new Date();
    for (let y = date.getFullYear(); y <= date.getFullYear() + 10; y++) {
      years.push({ key: y });
    }
    years.splice(0, 1);
    return years;
  }

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.ProjectSale = new ProjectSale();
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.projectedSales = {
      id: 0,
      branch: {},
      year: {},
      weeksales: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ],
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = " Add Week Projected Sales for the year";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.projectedSales.weeksales.length >= 53) {
      if (this.projectedSales.id != 0) {
        this.ProjectSale.updateItem(this.projectedSales).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.ProjectSale.saveItem(this.projectedSales).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.projectedSales = {
        id: 0,
        branch: "",
        year: "",
        weeksales: [],
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmDelete(data) {
    this.projectedSales.id = data.yearly_planned_id;
    console.log(data.yearly_planned_id);
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  deleted() {
    this.statusDialog = false;
    this.ProjectSale.deleted(this.projectedSales).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.ProjectSale.getItems(page).then((data) => {
      this.lists = data.projected_list;
      this.branchList = data.branches_list;
      console.log(this.lists);
    });
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>
