
import { Options, Vue } from "vue-class-component";
import PrinterSetup from "../../service/PrinterSetup";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class PrinterSetups extends Vue {
  private imagePath = "";
  private lists = [];
  private printerSetup;
  private keyword = "";
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private loading = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Printer settings" },
  ];
  private toast;

  private selectedPrinterTypeObj = {};
  private printerType = [{ value: "Regular" }, { value: "Dry Cleaning" }];

  private product = {
    id: 0,
    name: "",
    fontsize: 0,
    type: {},
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.printerSetup = new PrinterSetup();
    this.toast = new Toaster();
    this.imagePath = this.printerSetup.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      fontsize: 0,
      type: {},
    };

    this.submitted = false;
    this.dialogTitle = "Add New Service";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.printerSetup.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.printerSetup.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        fontsize: 0,
        type: {},
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Edit Printer";
    this.productDialog = true;

    this.printerSetup.getItem(data).then((res) => {
      if (res) {
        this.printerType.filter((elem) => {
          if (elem.value == res.printer_type) {
            this.selectedPrinterTypeObj = elem;
          }
        });

        this.product = {
          id: res.printer_id,
          name: res.printer_name,
          fontsize: res.fontsize,
          type: this.selectedPrinterTypeObj,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmDelete(data) {
    this.product.id = data.printer_id;

    this.statusDialog = true;
  }

  //DELETE THE iTEM
  deleted() {
    this.statusDialog = false;
    this.printerSetup.deleted(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.printerSetup.getItems(page).then((data) => {
      this.lists = data.print_record;
      this.totalRecords = data.total_printer_count;
      this.limit = data.limit_per_page;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.printerSetup.getSearchedPrinterSetup(this.keyword).then((data) => {
        this.lists = data.print_record;
        this.checkPagination = data.pagination;
      });
    }
  }
}
